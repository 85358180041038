@mixin h2 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 140%;
  color:#FFFFFF;
  @media (max-width: 1440px) {
    font-size: 22px;
  }
  @media (max-width: 1366px) {
    font-size: 20px;
  }
  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 120%;
  }
}
@mixin h3 {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 1;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;

  @media (max-width: 1366px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: 1200px) {
    font-size: 16px;
    line-height: 120%;
  }
}
@mixin big-text {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;

  @media (max-width: 1366px) {
    font-size: 14px;
  }
  @media (max-width: 1200px) {
    line-height: 120%;
  }
}
@mixin text {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 140%;

  @media (max-width: 1200px) {
    line-height: 120%;
  }
}
