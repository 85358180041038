@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.cart-item {
  display: flex;
}
.order__cost{color:#FFFFFF;} 
.order__payment{color:#FFFFFF;}
.MuiInputBase-input{color:#ffba00;}
.cart-item__image {
  margin-right: 25px;
  width: 80px;
  height: 80px;
  border-radius: 6px;
  object-fit: cover;
  object-position: center;
}
.cart-item__top {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
.cart-item__name {
  @include text;
  line-height: 100%;
  margin-right: 25px;
}
.cart-item__remove {
  position: relative;
  padding: 0;
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
  font-size: 0;

  &::after,
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #FFFFFF;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
}
.cart-item__bottom {
  display: flex;
  align-items: center;
}
.cart-item__counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  margin-right: 20px;

  b {
    font-weight: bold;
    margin-left: auto;
    color:#FFFFFF;
  }
  .button:last-of-type {
    margin-left: auto;
  }
}
.cart-item__price {
  @include text;
}

@media (max-width: 400px) {
  .cart-item__image {
    display: none;
  }
}
