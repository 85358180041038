@import "../../assets/scss/mixins.scss";
@import "../../assets/scss/variables.scss";

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}
.modal__container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 50px 60px 70px;
  overflow-y: auto;
  max-width: 850px;
  width: 100%;
  border-radius: 30px;
  background-color: #393939;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  color:#FFF;

  &.entering {
    animation: fade-modal-show 0.5s forwards;
  }
  &.exiting {
    animation: fade-modal-hide 0.5s forwards;
  }
  .button {
    align-self: flex-end;
  }
}
@keyframes fade-modal-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-modal-hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.modal__body {
  margin-bottom: 20px;
}
.modal__title {
  @include h3;
  margin-bottom: 25px;
}
.modal__close {
  position: absolute;
  width: 26px;
  height: 25px;
  right: 30px;
  top: 30px;
}
.modal__subtitle {
  @include h3;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 20px;
}
.modal__text {
  @include big-text;
  margin: $margin-less-1200 0;
  font-weight: 400;

  &--yellow {
    color: $primary;
  }
}
.modal__small-text {
  margin: 10px 0;
  font-size: 12px;

  &--gray {
    &,
    & * {
      color: $secondary;
    }
  }
}
.modal__list {
  li {
    @include big-text;
    font-weight: 400;
  }
  li:not(:last-of-type) {
    margin-bottom: 1em;
  }
}

.zones-table {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 14px;
}
.zones-table__head,
.zones-table__text {
  padding: 15px;
  border: 1px solid $primary;
}

@media (max-width: 900px) {
  .modal__container {
    max-width: calc(100% - #{$padding-less-1200 * 2});
  }
  .modal__small-text {
    font-size: 11px;
  }
}

@media (max-width: 500px) {
  .zones-table {
    font-size: 12px;
    overflow-x: auto;
  }
  .zones-table__head,
  .zones-table__text {
    padding: 3px;
  }
}
