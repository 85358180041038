// ==== Глобальные стили ====
body {
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #393939;

  * {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    box-sizing: border-box;
  }
}
.flex-colum {
  flex-direction: column;
}

html {
  min-height: 100vh;
  scroll-behavior: smooth;
}
#root {
  height: 100%;
  min-height: 100vh;
}
#portal {
  position: relative;
}
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}
header {
  position: relative;
  z-index: 1;
}
section,
footer {
  position: relative;
}
header,
footer {
  flex: 0 0 auto;
}
footer {
  margin-top: auto;
}
main {
  flex: 1 0 auto;
}
section {
  margin-top: $margin-over-1200;
  width: 100%;
}
img {
  max-width: 100%;
  height: auto;
  font-size: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  transition: color, opacity, background-color, border ease 0.25s;

  &:hover {
    color: $primary;
  }
}
button {
  border: none;
  background-color: transparent;
  transition: color, opacity, background-color, border-color ease 0.25s;
}
a,
button {
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: $primary solid 2px;
  }
  &:active {
    outline: none;
  }
}
p,
figure {
  margin: 0;
}
fieldset {
  border: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: black !important;
}

// ==== Общие классы ====
.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}
.main__container {
  max-width: $desktop-width;
  width: 100%;
  margin: 0 auto;
}
.main_blocks {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  gap: 32px;
  width: 100%
}
.title {
  &,
  & * {
    @include h2;
    margin-bottom: $margin-over-1200;
    color: $primary;
  }
}
.subtitle {
  @include h3;
  color: #3b3b3b;
}
.text {
  @include text;
}
.back {
  display: block;
  @include big-text;
  margin-top: $margin-over-1200;
  transition: ease 0.2s;
  color: $secondary;

  svg {
    margin-right: 15px;
  }
  path {
    transition: ease 0.2s;
  }
  &:hover path {
    fill: $primary;
  }
}

@media (max-width: 1280px) {
  section {
    padding: 0 $padding-over-1200;
  }
  .back {
    margin-left: $margin-over-1200;
  }
}
@media (max-width: 640px) {
  .back {
    margin-top: 0;
  }
}
@media (max-width: 530px) {
  section {
    padding: 0 $padding-less-1200;
  }
  .back {
    margin-left: $margin-less-1200;
  }
}
