// ==== Цвета ====
$primary: #FF511B;
$secondary: #a7a7a7;
$black: #1e1e1e;
$gray-1: rgb(226, 226, 226);
$gray-2: rgb(168, 168, 168);

// ==== Размеры и отступы ====
$header-height: 74px;
$categories-height: 45px;
$desktop-width: 1200px;
$margin-over-1200: 30px;
$padding-over-1200: 30px;
$margin-less-1200: 15px;
$padding-less-1200: 15px;
