@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';

.categories_two { 
  padding-bottom: 15px;
  top: $header-height;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 100;
  padding-top: 15px;
  padding-bottom: 15px;
}
.categories_two__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; 
  gap: 10px;
  width: 100%;
  

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  li {
    text-align: center;
    border: 5px solid $gray-1;
    border-radius: 20px;

    a {
      display: inline-block;
      padding: 11px 22px;
      min-width: fit-content;
      width: 100%;
      @include text;
      white-space: nowrap;
    }
    a:hover {
      color: $black;
    }
  } 
  li:hover {
    border-color: $gray-2;
  }
  li.active {
    border-color: $primary;
  }
}

@media (max-width: 1280px) {
  .categories_two__list {
    padding-left: $padding-over-1200;
    padding-right: $padding-over-1200;
  }
}
@media (max-width: 1200px) {
  .categories_two {
    top: 54px;
  }
}
@media (max-width: 640px) {
  .categories_two {
    top: 110px;
  }
}
@media (max-width: 530px) {
  .categories_two__list {
    padding-left: $padding-less-1200;
    padding-right: $padding-less-1200;
    overflow-x: scroll;
    justify-content: flex-start;
  }
}
