@import '../../assets/scss/variables.scss';

.notification {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 50px;
  gap: 75px;

  border: 2px solid #FFD12E;
  border-radius: 25px;
}
.notification__block {   
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 25px;

  width: 78px;
  height: 78px;
  width: 100%;
}
.notification__p {   
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 25px;

  width: 78px;
  height: 78px;
}

.notification__btn {  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 63px;
  gap: 10px;

  background: #FFD12E;
  border-radius: 31px;
}

.notification__btn:hover {  
  color: white;
  background: #FF511B;
}
.notification__image { 
  object-fit: cover;
  
  order: 0;
  object-position: center;
}
.notification__block h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600; 
  line-height: 120%;
  font-size: 32px;
  order: 1;
}

@media (max-width: 530px) {
  .notification {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    gap: 15px
  }
  .notification__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px;
    gap: 10px;
    
    height: 100%;

  }
  .notification__block h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: x-large;
    line-height: 120%;
    order: 0;
  }
  .notification__image { 
    width: 56px;
    height: 56px;
    object-fit: cover;
    object-position: center;
    
  order: 1;
  }
  .notification__btn {  
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 85px;
    gap: 7px;
  }
}
