@import '/src/assets/scss/variables.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 30px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  transition: ease 0.25s;
  white-space: nowrap;
  cursor: pointer !important;
  span {
    margin-right: 20px;
  }
}

@media (max-width: 1366px) {
  .button {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .button {
    font-size: 14px;
  }
}
