@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';

.footer {
  padding-top: 80px;
}
.footer__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $desktop-width;
  width: 100%;
  margin: 0 auto;
  padding: $padding-over-1200 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  & > div {
    display: flex;
    align-items: center;
  }
}
.footer__phone {
  @include big-text;
  color: $secondary;
}
.footer__socials {
  display: flex;
  align-items: center;
  margin-left: 20px;

  li:not(:last-child) {
    margin-right: 10px;
  }
}

#footer__politika {
  text-align: right;
}

#footer__policy_info {
  text-align: right;
}

.footer__policy {
  @include text;
  color: $secondary;


  &:hover {
    opacity: 1;
    color: $primary;
  }
}
.footer__made-by {
  display: flex;
  align-items: center;

  span {
    @include text;
    color: $secondary;
    line-height: 100%;
  }
  a {
    margin-left: 15px;
    font-size: 0;
  }
  img {
    margin-top: -8px;
    max-height: 30px;
  }
}

@media (max-width: 1280px) {
  .footer__container {
    padding: $padding-over-1200;
  }
}
@media (max-width: 768px) {
  .footer__container {
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;
    align-items: center;
  }
  .footer__made-by {
    margin-top: 10px;
  }
}
