@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';


.header_adress {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 20px;
  gap: 9px;

  width: 273px;
  height: 41px;
    
  font-size: 14px;
  line-height: 20px;
  background-color:#797979;
  border-radius: 12px;
  color:#FFFFFF;
}
.header_adress-mob {
  display: none;
}
.header_logo {
img{
max-width: 20%;
}

}
.header_burger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 11px;
  gap: 4.75px;

  width: 41px;
  height: 41px;

  background: #F4F4F4;
  border-radius: 10px;
  margin-right: unset;
}
.header__left {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;
}
.header__right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 48px; 
}
.header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  width: 100%;
  z-index: 100;
}
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: $desktop-width;
  margin: 0 auto;
  padding: $padding-less-1200 0;
  background-color: #393939;

  & > div {
    display: flex;
    align-items: center;
 
  }
}

.header__phone {
  @include big-text; 
  width: 119px;
  height: 20px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 140% */

  display: flex;
  align-items: center;

  color: #ffffff;
}
.header__left_group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px; 
  height: 100%;
}
.header__modal {
  padding-bottom: unset;
  overflow-y: hidden;
}
.burger {
  box-sizing: border-box; 
  position: absolute;
  width: 307px;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: #393939;
  box-shadow: 4px 0px 10px rgba(182, 182, 182, 0.09);
  z-index: -1;  
}
.burger-open {
  
  animation: open 0.4s ease;
}
.burger-close {
  animation: close 0.4s ease-in-out forwards;

}
@keyframes open {
  from {
    display: block;
    left: -307px;
    opacity: 0;
  }
  to {
    
    left: 0px;
    opacity: 1;
  }
}
@keyframes close {
  from {
    display: block; 
    opacity: 1;
  }
  to {
    left: -307px;
    opacity: 0;
    display: none;
  }
}
.burger__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 130px 0 58px 63px;
  height: 100%;

  button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 122% */

    display: flex;
    align-items: center;

    color: #FFFFFF;
  }
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    /* identical to box height, or 18px */


    color: #8F8F8F;
  }
}
.burger__body_group_top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
  a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 122% */
    width: 100%;
    display: flex;
    align-items: center;

    color: #FFFFFF;
  }
}
.burger__body_group_bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  button {
    display: flex;
    justify-content: flex-start;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    text-align: start;
    padding: unset;
    /* black */

    color: #FFFFFF;
  }
}
.burger__body_group { 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 30px;
  width: 244px;
}
.header__cart {
  position: relative;

  path,
  .header__cart-count {
    transition: ease 0.2s;
  }
  &:hover {
    path {
      stroke: $primary;
    }
    .header__cart-count {
      color: $primary;
    }
  }
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  svg {
    width: 30px;
    height: 30px;
  }
}
.header__cart-count {
  display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px;
  position: absolute;
  bottom: 1em;
  left: 1.5em;
  @include text;
  background: #FF511B;
  border-radius: 117.582px;
    
  width: 18.81px;
  height: 18.81px;
}

@media (max-width: 1280px) {
  .header__container {
    padding-left: $padding-over-1200;
    padding-right: $padding-over-1200;
  }
}
@media (max-width: 1200px) {
  .header {
    height: 54px;
  }
  .header__container {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .header__left {
    gap: 12px;

  }
  .header_adress {
    padding: 6px 20px 6px;
  }
  .header__right {
        
    gap: 12px;
  }
}
@media (max-width: 640px) {
  .header {
    height: 70px;
  }
  .burger {
    width: 100vw;
  }
  .header__left {
    gap: 12px;

  }
  .header__left_group {
        
    gap: 24px;
  }
  .header__container {
    padding: 12px 15px;
    align-items: center;  
  }
}
@media (max-width: 450px) {
  .header__modal {
    padding: 60px 15px 0px;
    padding-bottom: unset;
    overflow-y: hidden;
    width: 100%;
    height: 100%;
    /* padding: unset; */
    bottom: calc(100% - 1000px);  
    bottom: 0;
    left: 0;
    top:unset;
    transform: unset;
    border-radius: 30px 30px 0px 0px;
    max-width: calc(100% - 0px);
    max-height: calc(80vh);
  }
  .header_adress_text {
    display: none;
  }
  .header__container {
    .header__phone {
      display: none;

    }   
    .header_adress{  
      background: unset;
      border-radius: 12px;
      width: 100%;
    }
    .header_adress-mob {

    }
    svg {
      width: 22px !important;
    }
    .header__cart-count {
      font-size: 12px !important;
    }
  }
}
