
.dilivery__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 32px;

  width: 100%;
  height: 100%;


  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  z-index: 0;
} 

.dilivery__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 25px;
  width: 100%;
}
.dilivery__header-buttons {
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;

}
.dilivery__header-inputs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  /* Inside auto layout */
  
  flex: none;
  order: 1; 
  flex-grow: 0;

}
.ymaps-2-1-79-controls__control_toolbar {
  display: none;
}
.dilivery__ymaps {
  position: relative;
  width:  calc(100% + 120px);
  left: -60px; 
  height: 250px;
  border: unset;
  border-radius: 20px;
}
.delivery_button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 8px;
 
  height: 48px;

  background: #F4F4F4;
  border-radius: 16px;

  /* Inside auto layout */
  transition: unset;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.delivery_button_save { 
  padding: 13px 20px 13px;
} 
.delivery_button:hover { 
 
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;

  background: white;
  border: 2px solid #E2E2E2; 
}
.delivery_button_save:hover { 
  padding-left: 18px;
  padding-right: 18px;
}
.delivery_button_active { 
 
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;

  background: white;
  border: 2px solid #E2E2E2; 
}
.textarea__adress {
  width: 100%;
  background: unset;
  
  height: 48px;
  border: unset;
  
}
.textarea__adress_group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12.3333px 20px 13.2604px;
  gap: 9px;

  width: 100%;
  height: 48px;

  border: 2px solid #E2E2E2;
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
} 
.textarea__adress:focus-visible {
  width: 100%;
  background: unset;
  border: unset;
  outline:none;
  color:#FFF;
}
.input_dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 0px;

  position: absolute;  
  left: 16px;
  top: 188px;

  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04), 0px 0px 6px rgba(0, 0, 0, 0.04);
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
  z-index: 3;
}
.input_dropdown_input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 20px;
  gap: 10px;

  background: unset;
    
  height: 36px; 
}
.input_dropdown_input:hover {
  background: #F4F4F4;
  
}
@media (max-width: 450px) {
  .dilivery__body {
    justify-content: flex-start;
    align-items: flex-start;

  }
  
  .dilivery__header-inputs { 
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
  }
  .delivery_button {
    width: 48%;
  }
  .delivery_button_save {
    width: 100%;
  }
  .dilivery__ymaps {
    top: unset;
    width: 100%;
    height: 60%;
    left: unset;
    
  }
}