@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';

.cart {
  width: 100%;
  height: 100%;
}
.cart-item__name{
    color:#FFFFFF;
}
.button cart-item__remove{
    color:#FFFFFF;
}
.cart-item__price {
    color:#FFFFFF;
}
.cart--empty,
.cart--successful {
  display: flex;
  flex-direction: column;
  align-items: center;

  .cart__title {
    text-align: center;
    color: #FFFFFF;
  }
}
.cart__container {
  margin: 0 !important;
  width: 100% !important;

  & > div {
    width: 50%;
  }
  & > div:first-child {
    padding-right: 5%;
  }
  & > div:last-child {
    padding-left: 5%;
  }
}
.cart__column {
  padding: 0 !important;
  width: 50%;

  &:first-child {
    padding-right: 5% !important;
    border-right: 1px solid #e0e0e0;
  }
  &:last-child {
    padding-left: 5% !important;
  }
}
.cart__title {
  font-size: 24px;
  margin: 0;
  font-weight: 400;
  color:#FFFFFF;
}
.cart--empty,
.cart--successful {
  .cart__title {
    margin: $margin-over-1200 0;
  }
}
.cart__back {
  padding: 1em 2em;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: 0.02em;
  border: 1px solid #FFF;
  color:#FFFFFF;

  &:hover {
    border-color: $primary;
    color: #000;
  }
}
.cart__prompt {
  display: flex;
  justify-content: center;
  padding: 20px;
  margin: $margin-over-1200 0;
  font-size: 14px;
  border-radius: 6px;
  background-color: #797979;

  .button {
    text-decoration: underline;
  }
}
.delivery-time__info {
  margin-bottom: 15px;
  font-size: 12px;

  b {
    font-weight: 600;
  }
}
.delivery-time {
  margin-bottom: 16px;
}
.delivery-time__select {
  display: flex;
}
.delivery-time__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-self: center;
  height: fit-content;

  .button {
    padding: 0.5em 1em;
    font-size: 12px;
    border: 1px solid #000;
  }
  .button.active {
    color: #fff;
    background-color: #000;
  }
}
.order {
  margin-bottom: 78px;
}
.order__list {
  padding: 30px 0;

  li:not(:last-child) {
    padding-bottom: 25px;
  }
}
.order__cost {
  @include big-text;
  margin-bottom: 10px;
}
.order__payment {
  @include h3;
}
.select-container {
  display: flex;
  align-items: flex-end;

  img {
    margin-right: 5%;
    padding-bottom: 5px;
  }
  & > div {
    width: 100%;
    min-width: 0px !important;
  }
}

@media (max-width: 1366px) {
  .cart__back {
    font-size: 16px;
  }
}
@media (max-width: 1200px) {
  .cart__title {
    margin: $margin-less-1200 0;
  }
  .cart__back {
    font-size: 14px;
  }
}
@media (max-width: 960px) {
  .cart__column {
    &:first-child {
      padding-top: 40px !important;
      padding-right: 0 !important;
      border-right: 0 !important;
      border-top: 1px solid #e0e0e0;
      order: 2;
      color:#FFFFFF;
    }
    &:last-child {
      padding-bottom: 40px !important;
      padding-left: 0 !important;
      order: 1;
    }
  }
}
@media (max-width: 768px) {
  .cart__title {
    font-size: 20px;
  }
}
@media (max-width: 400px) {
  .order__list {
    padding: 30px 0;

    li {
      padding: 30px 0;
    }
    li:last-child {
      padding-bottom: 0;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }
  }
}
@media (max-width: 320px) {
  .cart__prompt {
    font-size: 12px;

    .button {
      font-size: 12px !important;
      line-height: 100% !important;
    }
  }
}
