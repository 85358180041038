.cart-slider {
  position: relative;
  padding: 0px 50px;

  .slick-slider {
    overflow: hidden;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    padding: 30px 0;
    margin-right: 10px;
  }
  .slick-list {
    width: 130%;
  }
  & > .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
  }
  & > .button:first-child {
    left: 0;
  }
  & > .button:last-child {
    right: 0;
  }
}
.cart-slider__slide {
  padding: 35px 18px;
  background: #393939;
  border: 1px solid #FF511B;
  box-shadow: 0px 0px 7px rgba(35, 35, 35, 0.06);
  border-radius: 22px;
}

@media (max-width: 1200px) {
  .cart-slider {
    .slick-list {
      width: 150%;
    }
  }
}
@media (max-width: 768px) {
  .cart-slider {
    .slick-list {
      width: 100%;
    }
  }
}
@media (max-width: 460px) {
  .cart-slider {
    padding: 0px 20px;
  }
}
