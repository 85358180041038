@import "../../assets/scss/mixins.scss";
@import "../../assets/scss/variables.scss";

.product {
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
  min-height: 230px;
  transition: linear 0.2s;
  overflow: hidden;
  z-index: 0;

  &:hover {
    border: 1px solid $primary;
  }

  &--added {
    background-color: #797979;
    box-shadow: 0 0 8px 1px #c6c5c5;

  }

  &--added:hover {
    border-color: rgba(0, 0, 0, 0.15);
  }
}

.product__description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $padding-over-1200;
  width: 60%;
}

.product__name {
  @include h3;
  color:#FFFFFF;
}

.product__weight {
  display: block;
  @include text;
  margin: 10px 0;
  opacity: 0.5;
  color:#ffffff;
}

.product__compound {
  @include text;
  margin-top: $margin-over-1200;
  margin-top: 15px;
  color:#ffffff;
  span {
    opacity: 0.5;
  }
}

.product__photo {
  position: relative;
  width: 40%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.product__bottom {
  display: flex;
  //justify-content: space-between;
  align-items: center;
  margin-top: $margin-over-1200;
  width: 100%;
}

.product__position {
  width: 50%;
  font-size: 14px;
  height: 100%;
  &:first-child {
    border-right: 1px solid #FF511B;
    padding-right: 20px;
  }

  &:last-child {
    padding-left: 20px;
  }

  &-title {
    color: #A7A7A7;
  }

  &-weight {
    color: #A7A7A7;
    margin: 5px 0px;
  }
}

.product__buttons{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product__counter {
  display: flex;
  align-items: center;

  b {
    font-weight: bold;
    padding:0px 5px 0px 5px;
    color:#FFF;
  }

  .button:last-of-type {
    margin-left: auto;
  }
}
.product__count{
  margin: 0 10px;
}
.product__price {
  @include text;
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  color:#FFFFFF;
  b {
    font-weight: bold;
    margin-left: auto;
  }
}

.modal {
  .cart-slider {
    .slick-track {
      column-gap: 10px;
    }

    .slick-list {
      width: 100%;
    }

    .slick-slide {
      margin-right: 0;
    }

    .recommended-item {
      flex-direction: column;

      &.gift {
        button {
          padding: 0.4em 0.7em;
        }
      }
    }

    .recommended-item__image {
      width: 100%;
      height: 240px;
      object-fit: cover;
      margin: 0 auto 20px;
    }

    .product__counter {
      width: unset;
      column-gap: 10px;
    }
  }
}

@media (max-width: 1200px) {
  .product__description {
    padding: $padding-less-1200;
  }

  .product__compound {
    margin-top: $margin-less-1200;
  }
}

@media (max-width: 530px) {
  .product {
    flex-direction: column-reverse;
  }

  .product__photo {
    height: 250px;
    width: 100%;
  }

  .product__description {
    width: 100%;
  }
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #FFBA00 !important;
}


.MuiSwitch-thumb {
  background-color: #FFBA00 !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1;
}
