@import '../../assets/scss/mixins.scss';
@import '../../assets/scss/variables.scss';

.categories {
  position: sticky;
  padding-bottom: 15px;
  top: $header-height;
  left: 0;
  right: 0;
  background-color: #393939;
  z-index: 1;
  height: 200px;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}



.categories__list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  max-width: $desktop-width;
  margin: 0 auto;
overflow-x: auto;
  li {
    text-align: center;
    border: 2px solid $gray-1;
    border-radius: 20px;

    a {
      display: inline-block;
      padding: 11px 22px;
      min-width: fit-content;
      width: 100%;
      @include text;
      white-space: nowrap;
      color:#FFFFFF;
    }
    a:hover {
      color: #ffba00;
    }
  }
  li:not(:last-of-type) {
    margin-right: 15px;
  }
  li:hover {
    border-color: $gray-2;
  }
  li.active {
    border-color: $primary;
  }
}

@media (max-width: 1280px) {
  .categories__list {
    padding-left: $padding-over-1200;
    padding-right: $padding-over-1200;
  }
}
@media (max-width: 1200px) {
  .categories {
    top: 54px;
  }
}
@media (max-width: 640px) {
  .categories {
    top: 68px;
  }
}
@media (max-width: 530px) {
  .categories__list {
    padding-left: $padding-less-1200;
    padding-right: $padding-less-1200;
  }
}
