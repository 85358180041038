@import '../../assets/scss/variables.scss';
@import '../../assets/scss/mixins.scss';

.recommended-item {
  display: flex;
  width: 100% !important;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.recommended-item__image {
  margin-right: 15px;
  min-width: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}
.recommended-item__name {
  display: inline-block;
  @include text;
  line-height: 100%;
  margin-bottom: auto;
  color:#FFFFFF;
}
.recommended-item__remove {
  position: relative;
  padding: 0;
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
  font-size: 0;

  &::after,
  &::before {
    position: absolute;
    left: 0;
    top: 50%;
    content: '';
    width: 100%;
    height: 1px;
    background-color: #000;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
}
.recommended-item__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.recommended-item__counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90px;
  margin-right: 20px;

  b {
    font-weight: bold;
    margin-left: auto;
  }
  .button:last-of-type {
    margin-left: auto;
  }
}
.recommended-item__price {
  @include text;
  color: $primary;
  font-weight: bold;
}

@media (max-width: 320px) {
  .recommended-item__name {
    font-size: 12px;
  }
}
